const jQueryBridget = require('jquery-bridget');
const Flickity = require('flickity');
// make Flickity a jQuery plugin
jQueryBridget('flickity', Flickity, $);


// import Swiper JS
import Swiper from 'swiper/swiper-bundle'

const swiper = new Swiper('.swiper', {
  speed: 700,
  navigation: {
    nextEl: '.next',
    prevEl: '.prev',
  },
  mousewheel: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
    renderBullet: function (index, className) {
      return `<span class="${className} mx-2" ><i class="fas fa-circle fa-xs pagination-dot"></i></span>`;
    },
  },
  on: {
    init: function() {
      $(`.title-wrapper:first`).addClass('active-slide')
    },
    slideChange: function() {
      $(`.title-wrapper`).removeClass('active-slide')
      const e = $(`.title-wrapper:eq(${swiper.activeIndex})`)
      e.addClass('active-slide')
    }
  }
})

$('.swiper-slide').on('click', e => {
  window.location.href = e.currentTarget.dataset.url
})